.adressloactionDiv {
  margin-top: 2rem;
  border: 1px solid rgb(96 165 250);
  border-radius: 6px;
  padding: 1.9rem 1rem;
  background-color: #eff6ff80;
  position: relative;
}
.locationtitle {
  display: flex;
  align-items: center;
  width: max-content;
  background-color: #3b82f6;

  padding: 10px 12px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: -20px;
}
