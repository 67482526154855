.submittedDiv {
  background-color: white;
  width: 70%;
  height: max-content;
  text-align: center;
  border-radius: 15px;
  padding: 1.25rem
}
.submittedDiv h1{
 width: 90%;
 margin: auto;
}
.thankyouimg {
  width: 50%;
  height: 80%;
  border: none;
  outline: none;
  margin: auto;
}
.refreshicon {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .submittedDiv h1{
    font-size: 1rem;
  }
  .thankyouimg{
    width: 80%;
  }
}