.privacydiv{
    background-color: #ffffff;
    width: 50%;
    border-radius: 20px;
    padding: 2rem;
    display: flex;
    flex-direction: column;  
    text-align: justify; 
}
.privacydiv h1.main_title{
    margin: 0px 0px 20px 0px;
    text-align: center;
}
.privacydiv h3{ margin-bottom: 10px; margin-top: 10px;}
.privacydiv p{ margin-bottom: 15px; margin-top: 0px;}
.privacydiv p a{color: #1ca9af;}
.privacydiv p a:hover{ text-decoration: none;}
.terminnerDiv ol{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.pointsp{
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    .privacydiv {
      width: 70%;
      padding: 1rem;
    }
  }
  